<template>
  <div class="columns is-vcentered">
    <div class="column is-narrow">
      <ToggleButton
        :speed="250"
        class="is-size-7"
        :value="value"
        :labels="{
          checked: '',
          unchecked: ''
        }"
        :width="toggleConfig.width"
        :height="toggleConfig.height"
        :color="toggleConfig.colors"
        :switch-color="toggleConfig.switchColour"
        :sync="true"
        @change="toggle"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { toggleColours } from 'modules/ui/services'
import { xor } from 'lodash'
export default {
  name: 'StockviewWarningToggle',
  computed: {
    ...mapGetters('stockview/listings', ['filters']),
    value() {
      const currentFilters = this.filters.pricePosition
      const warningFilters = ['UnderPriced', 'OverPriced']
      return !xor(currentFilters, warningFilters).length
    },
    toggleConfig() {
      return {
        colors: toggleColours(),
        height: 30,
        width: 50,
      }
    }
  },
  methods: {
    ...mapActions('stockview/listings', ['updateFilters']),
    async toggle() {
      const type = 'pricePosition'
      const payload = !this.value ? ['UnderPriced', 'OverPriced'] : null
      const count = payload ? 2 : 0
      this.updateFilters({ filters: [{ type, payload }], count, type })

      this.$mxp.track(`stockview_stock_warning_toggle_${count ? 'on' : 'off'}`)
    }
  }
}
</script>
